import React, { useEffect, useState } from 'react';
import { Link, graphql } from 'gatsby';

import Bio from '../components/bio';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { rhythm } from '../utils/typography';
import waving from '../components/hi.gif';
import ReactModal from 'react-modal';
import useLocalStorage from 'use-local-storage';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '50rem',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '95%',
  },
};

const adbox = `<div id="ad-container"></div>`;

const BlogPostTemplate = (props) => {
  const post = props.data.markdownRemark;
  const siteTitle = props.data.site.siteMetadata.title;
  const { previous, next } = props.pageContext;
  const [hasDismissedModal, setHasDismissedModal] = useLocalStorage(
    'dismissedModal',
    true
  );
  const [showNewsletter, setShowNewsLetter] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      console.log(hasDismissedModal, window.innerWidth);
      if (hasDismissedModal === false && window.innerWidth > 1000) {
        setShowNewsLetter(undefined);
      }
    }, 3000);
    return () => clearTimeout(timeout);
  }, [hasDismissedModal]);

  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('async', 'true');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute(
      'src',
      '//cdn.carbonads.com/carbon.js?serve=CWYDVK3J&placement=typeofnandev'
    );
    script.setAttribute('id', '_carbonads_js');
    document.getElementById('ad-container').appendChild(script);
  }, []);

  const newsletterContent = (
    <>
      <h1>🎓 Learn how the web works</h1>
      <p>
        One of the best ways to level up your tech career is to have a great
        foundational understanding of how the web works. In my free newsletter,
        How the Web Works, I provide simple, bite-sized explanations for various
        web topics that can help you boost your knowledge. Join{' '}
        <strong>2,500+ other learners</strong> on the newsletter today!
      </p>
      <p>
        Signing up is free, I never spam, and you can unsubscribe any time. You
        won't regret it!
      </p>
      <a
        className="signupBtn"
        href="https://howthewebworks.substack.com?r=31c2xe"
      >
        Sign up for the newsletter &raquo;
      </a>
    </>
  );

  return (
    <>
      <Layout location={props.location} title={siteTitle}>
        <Seo
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          postData={post}
        />
        <h1>{post.frontmatter.title}</h1>
        <p
          style={{
            fontSize: '1rem',
            display: `block`,
            marginBottom: rhythm(1),
          }}
        >
          {post.frontmatter.author}
          <br />
          {post.frontmatter.date}
        </p>

        <div dangerouslySetInnerHTML={{ __html: adbox + post.html }} />
        <hr className="newsletter-divider" />
        <Bio />
        <ul
          className="bottom-links"
          style={{
            display: `flex`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
            gap: '2rem',
          }}
        >
          {previous && (
            <li style={{ marginBottom: 0 }}>
              <Link
                to={previous.fields.slug}
                rel="prev"
                style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}
              >
                ← {previous.frontmatter.title}
              </Link>
            </li>
          )}
          {next && (
            <li style={{ textAlign: 'right', marginBottom: 0 }}>
              <Link
                to={next.fields.slug}
                rel="next"
                style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}
              >
                {next.frontmatter.title} →
              </Link>
            </li>
          )}
        </ul>
      </Layout>
      {showNewsletter === undefined && (
        <div className="promo">
          <h1>
            <img src={waving} alt="waving hand" /> Hey there!
          </h1>
          <p>Can I show you something cool I've been working on?</p>
          <button
            className="sureBtn"
            onClick={() => {
              setShowNewsLetter(true);
              setHasDismissedModal(true);
            }}
          >
            Sure!
          </button>
          <button
            onClick={() => {
              setShowNewsLetter(false);
              setHasDismissedModal(true);
            }}
          >
            No thanks
          </button>
        </div>
      )}
      <ReactModal style={customStyles} isOpen={showNewsletter}>
        {newsletterContent}
        <footer className="modalFooter">
          <button
            className="noThanks"
            onClick={() => {
              setShowNewsLetter(false);
              setHasDismissedModal(true);
            }}
          >
            No thanks, take me back
          </button>
        </footer>
      </ReactModal>
    </>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      timeToRead
      fields {
        socialcard
      }
      frontmatter {
        title
        description
        date(formatString: "MMMM DD, YYYY")
        author
        link
        subject
      }
    }
  }
`;
