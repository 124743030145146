/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { rhythm } from '../utils/typography';
import { Links } from './links';

function Bio({ hideLinks }) {
  return (
    <StaticQuery
      query={bioQuery}
      render={(data) => {
        const image = getImage(data.light);
        const { author } = data.site.siteMetadata;
        return (
          <>
            <div
              style={{
                display: `flex`,
                alignItems: `center`,
                marginBottom: `40px`,
                fontSize: '1.4rem',
              }}
            >
              <GatsbyImage
                image={image}
                alt={author}
                style={{
                  marginRight: rhythm(1 / 2),
                  marginBottom: 0,
                  minWidth: 50,
                  borderRadius: `100%`,
                }}
                imgStyle={{
                  borderRadius: `50%`,
                }}
              />
              <div>
                <p style={{ margin: 0, padding: 0 }}>
                  <strong>{author}</strong> is a senior UI engineer at
                  Microsoft.
                  <br />
                </p>
                {!hideLinks && <Links />}
              </div>
            </div>
          </>
        );
      }}
    />
  );
}

const bioQuery = graphql`
  query BioQuery {
    dark: file(absolutePath: { regex: "/dark.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 100)
      }
    }
    light: file(absolutePath: { regex: "/light.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 100)
      }
    }
    site {
      siteMetadata {
        author
      }
    }
  }
`;

export default Bio;
