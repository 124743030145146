import { faGithub, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const Links = () => {
  return (
    <ul className="social-items">
      <li className="social-icon">
        <a href={`https://github.com/nas5w`} aria-label="Github" alt="Github">
          <FontAwesomeIcon icon={faGithub} />
        </a>
      </li>
      <li className="social-icon">
        <a
          href={`https://www.youtube.com/c/devtutsco`}
          aria-label="YouTube"
          alt="YouTube"
        >
          <FontAwesomeIcon icon={faYoutube} />
        </a>
      </li>
      <li>
        <a href="https://nick.scialli.me">About</a>
      </li>
      <li>
        <a href="https://interviewguide.dev">InterviewGuide.dev</a>
      </li>
    </ul>
  );
};
